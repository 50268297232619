import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
}

const Paragraph = ({
  children,
  className = 'text-sm leading-8 mt-8',
}: Props) => {
  return <p className={className}>{children}</p>
}

export default Paragraph
