type Props = {
  children: React.ReactNode
  className?: string
}

const ThemedBackground = ({ children, className = '' }: Props) => {
  return (
    <div
      className={`transp-light:text-black bright:text-black bright:bg-white black:bg-black transp-black:text-white blue:bg-blue blue:text-white transp-blue:text-white black:text-white light:bg-gray-700 ${
        className ?? ''
      }`}
    >
      {children}
    </div>
  )
}

export default ThemedBackground
