import { twMerge } from 'tailwind-merge'

type Props = {
  children: React.ReactNode
  className?: string
}

const LargeTitle = ({ children, className }: Props) => {
  return (
    <h1
      className={twMerge(
        'text-4xl leading-relaxed font-medium mb-9',
        className,
      )}
    >
      {children}
    </h1>
  )
}

export default LargeTitle
