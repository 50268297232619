import AdaptiveThemeBox from '@/atoms/AdaptiveThemeBox'
import ThemeBox from '@/atoms/ThemeBox'
import ThemedBackground from '@/atoms/ThemedBackground'
import { useLocale } from '@/hooks/useLocale'
import Container from '@/molecules/Container'
import Footer from '@/organisms/Footer'
import HeaderBar from '@/organisms/HeaderBar'
import { NextPage } from 'next'
import Head from 'next/head'
import LargeTitle from '@/atoms/LargeTitle'
import Paragraph from '@/atoms/Paragraph'
import NextLink from 'next/link'
import Button from '@/atoms/Button'
import Link from '@/atoms/Link'
import { PageNotFound } from '@/content/types'
import { attributes } from '@/content/page_not_found.md'
import MetaInformation from '@/utils/MetaInformation'

const NotFoundPage: NextPage = () => {
  const { translations, locale } = useLocale<PageNotFound>(attributes)

  return (
    <div className="min-h-sceen">
      <Head>
        <title>{translations.page_title}</title>\
        <MetaInformation locale={locale} {...translations.meta} />
      </Head>
      <AdaptiveThemeBox defaultTheme="black">
        <HeaderBar />
      </AdaptiveThemeBox>
      <ThemeBox theme="black">
        <ThemedBackground>
          <Container>
            <div className="flex flex-col gap-8 min-h-screen md:min-h-[60vh] md:justify-center">
              <LargeTitle className="max-w-2xl mb-0">
                {translations.title}
              </LargeTitle>
              <Paragraph className="max-w-3xl leading-loose">
                {translations.content}
              </Paragraph>
              <div className="flex gap-4 flex-col sm:flex-row w-fit">
                <Button>
                  <NextLink href="/">
                    {translations.home_page_button_title}
                  </NextLink>
                </Button>
                <Link href="/contact">
                  {translations.contact_us_button_title}
                </Link>
              </div>
            </div>
          </Container>
        </ThemedBackground>
      </ThemeBox>
      <ThemeBox theme="black">
        <Footer />
      </ThemeBox>
    </div>
  )
}

export default NotFoundPage
